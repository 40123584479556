import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import productsData from '../../products.json';
import { Link, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Breadcrumbs from '../../components/Breadcrumbs/Breadcrumbs';
import './PageTemplate.css';

async function fetchJSON(fileName) {
    const response = await fetch(`${process.env.PUBLIC_URL}/${fileName}`);
    if (!response.ok) {
      throw new Error(`Failed to load ${fileName}`);
    }
    return response.json();
}

// Функция для получения cookie
function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift();
    return null;
}

const PageTemplate = ({ children }) => {
    const { t } = useTranslation();
    const [products, setProducts] = useState([]);
    const { productTitle } = useParams(); // Извлекаем параметр из URL

    useEffect(() => {
        // Загружаем данные о продуктах и скидках параллельно
        Promise.all([fetchJSON('products.json')])
          .then(([productsData]) => {
            setProducts(productsData);
          })
          .catch(console.error);
      }, []);

    // Добавим состояние для текущего продукта
    const [currentProduct, setCurrentProduct] = useState(null);

    useEffect(() => {
        // Найдем продукт по его заголовку
        const product = products.find(prod => prod.title === productTitle);
        setCurrentProduct(product);
    }, [productTitle, products]); // обновляем при изменении productTitle или products

    // Обработчик для клика по ссылке
    const handleLinkClick = (product) => {
        // Получаем clickid из cookies
        const clickid = getCookie('clickid');
        // Формируем тип конверсии
        const conversionType = `${product.title.replace(/ /g, '_')}_View`;
        // Формируем URL постбека
        const postbackUrl = `https://track.vitapillen.org/postback?clickid=${clickid}&type=${conversionType}`;
        
        // Отправляем запрос
        fetch(postbackUrl, { method: 'GET' })
            .then(response => { 
                // Успешный запрос
                console.log('Postback success:', response);
            })
            .catch(error => { 
                // Обработка ошибок
                console.error('Postback error:', error);
            });
    };

    return (
        <>
            <div className="section-catalog">
                <Container>
                    <div className="catalog-container">
                        <div className="catalog-wrapper" data-aos="fade">
                            <Breadcrumbs />
                            {children}
                            {/* Выводим информацию о текущем продукте, если она доступна */}
                            {currentProduct && (
                                <div>
                                    <h1>{currentProduct.title}</h1>
                                    <p>{currentProduct.description}</p>
                                    {/* Другие данные о продукте */}
                                </div>
                            )}
                            <div className="wrapper-btn">
                                <h1 className='wrapper-btn-title' data-aos="fade-right">{t('continue')}</h1>
                                <Link to="/#catalog" duration={500} className="main-content-btn" data-aos="fade-right">
                                    <div className='btn-text'>{t('watch_catalog')}</div>
                                    <div className='btn-icon'>
                                        <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-down-right-white.svg`} alt="" />
                                    </div>
                                </Link>
                            </div>
                        </div>
                        <div className="sidebar" data-aos="fade-left">
                            <h1 className="sidebar-title">{t('all_products')}:</h1>
                            <ul className='sidebar-list'>
                                {products.map((product) => (
                                    <Link 
                                        to={`/${product.title}`} 
                                        key={product.title}
                                        onClick={() => handleLinkClick(product)} // Добавляем обработчик клика
                                    >
                                        <li className="sidebar-object">{product.title}</li>
                                    </Link>
                                ))}
                            </ul>
                        </div>
                    </div>
                </Container>
                <div className="sidebar-bg-wrapper" data-aos="fade-left">
                    <div className="sidebar-bg"></div>
                </div>
            </div>
        </>
    );
};

export default PageTemplate;
