/* global redtrack */

import React, { useEffect, useState } from 'react';
import { Row, Col, Card, Button } from 'react-bootstrap';
import productsData from '../../products.json';
import './Catalog.css';
import { Element } from 'react-scroll';
import PageTemplate from '../PageTemplate/PageTemplate';
import { Link } from 'react-router-dom';
// import { trackEvent } from '../../analytics';
import { useTranslation } from 'react-i18next';
import ReactGA from 'react-ga4';

async function fetchJSON(fileName) {
  const response = await fetch(`${process.env.PUBLIC_URL}/${fileName}`);
  if (!response.ok) {
    throw new Error(`Failed to load ${fileName}`);
  }
  return response.json();
}

function Catalog() {
  const { t } = useTranslation();
  const [products, setProducts] = useState(productsData);
  const [discounts, setDiscounts] = useState([]);
  const [visibleCount, setVisibleCount] = useState(3);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  useEffect(() => {
    // Загружаем данные о продуктах и скидках параллельно
    Promise.all([fetchJSON('products.json'), fetchJSON('config.json')])
      .then(([productsData, configData]) => {
        setProducts(productsData);
        setDiscounts(configData.discounts);
      })
      .catch(console.error);

    const handleResize = () => {
      setIsMobile(window.innerWidth <= 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);


  const getDiscountInfo = (productTitle) => {
    return discounts.find((item) => item.title === productTitle) || {};
  };

  const handleShowMore = () => {
    setVisibleCount((prevCount) => prevCount + 3);
  };

  const handleViewProduct = (product) => {
    function getCookie(name) {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
      return null;
    }

    // RedTrack: отслеживание события добавления в корзину
    redtrack('event', 'add_to_cart', {
      product_id: product.id,
      product_name: product.title,
      // variant: selectedOption.variant,
      // value: totalPrice,
      currency: 'EUR',
    });

    // Получаем данные
    const clickid = getCookie('clickid'); // Предположим, clickid хранится в cookie
    const conversionType = `${product.title.replace(/ /g, '_')}_View`; // Укажите тип конверсии

    // Формируем URL постбека
    const postbackUrl = `https://track.vitapillen.org/postback?clickid=${clickid}&type=${conversionType}`;

    // Отправляем запрос
    fetch(postbackUrl, { method: 'GET' })
      .then(response => {
        // Здесь можно обработать успешный ответ, если нужно
      })
      .catch(error => {
        // Здесь можно обработать ошибку, если нужно
      });

    ReactGA.event({
      category: 'Catalog',
      action: 'Click on Product',
      label: product.title,
    });
  };

  return (
    <Element name="section1">
      <PageTemplate>
        <Row xs={1} sm={2} md={3} className="g-6">
          {(isMobile ? products.slice(0, visibleCount) : products).map((product) => {
            const { discountPercentage = 0, promotion, new: isNew, recommended, hit } = getDiscountInfo(product.title);
            // const discountedPrice = discountPercentage
            //   ? (product.price.from * (1 - discountPercentage / 100)).toFixed(2)
            //   : product.price.from;

            return (
              <Col key={product.id}>
                <Card>
                  <div className="img-container">
                    <Card.Img variant="top" src={`${process.env.PUBLIC_URL}/${product.imageUrl}`} alt={product.title} />
                    <div className="sticker-container">
                      {
                        discountPercentage > 0 ? (
                          <span className="sticker discount-percentage">-{discountPercentage}%</span>
                        ) : (
                          <></>
                        )}
                      {promotion && <span className="sticker promotion">Promo</span>}
                      {isNew && <span className="sticker new">New</span>}
                      {recommended && <span className="sticker recommended">Recommended</span>}
                      {hit && <span className="sticker hit">Hit</span>}
                    </div>
                  </div>
                  <Card.Body>
                    <div>
                      <Card.Title>{product.title}</Card.Title>
                      <Card.Text>€{product.price.from} - €{product.price.to}</Card.Text>
                    </div>
                    <Link
                      to={`/${product.title}`}
                      className='catalog-btns btn'
                      onClick={() => handleViewProduct(product)}
                    >
                      {t('select_options')}
                    </Link>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
        </Row>

        {isMobile && visibleCount < products.length && (
          <div className="d-flex justify-content-center mt-3 mx-auto">
            <Button onClick={handleShowMore}>
              Load More
              <img src={`${process.env.PUBLIC_URL}/images/icons/arrow-down.svg`} alt="" />
            </Button>
          </div>
        )}
      </PageTemplate>
    </Element>
  );
}

export default Catalog;
